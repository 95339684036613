import { Col, Row } from "reactstrap";
import styles from "./prupose-desktop.module.css";
import Text from "../../atoms/text/text";
import { useTranslation } from "react-i18next";
import SvgLoader from "../../atoms/svg-loader/svg-loader";

const PruposeDesktop = () => {

    const {t} = useTranslation();

    return(
        <>
            <Row>
                <Col className={`position-relative m-auto d-flex flex-column align-items-end align-items-lg-center ${styles.cardContainer}`} lg={3}>
                    <div className={`mb-2 ${styles.button}`} style={{backgroundColor: "#4B708D"}}>UX</div>
                    <Text color="white" size="17px" align="center">{t("purpose-ux-text")}</Text>
                    <SvgLoader svgProps={{path: "images/icons/line-1.svg", width: "100%", className: "position-absolute", style: {bottom: "-65px"}}} />
                </Col>
            </Row>
            <Row>
                <Col className={`position-relative d-flex flex-column justify-content-center align-items-start ${styles.cardContainer} ${styles.m_top}`} lg={3}>
                    <div className={`mb-2 ${styles.button}`} style={{backgroundColor: "#153040"}}>{t("purpose-learn-title")}</div>
                    <Text color="white" size="17px" align="start">{t("purpose-learn-text")}</Text>
                    <SvgLoader svgProps={{path: "images/icons/line-2.svg", width: "100%", className: "position-absolute", style: {top: "50px", right: "-220px"}}} />
                </Col>
                <Col className="m-auto mt-3" lg={6}>
                    <SvgLoader svgProps={{path: "images/icons/purpose.svg", width: "100%"}} />
                </Col>
                <Col className={`position-relative ${styles.cardContainer} ${styles.m_top}`} lg={3}>
                    <div className="mt-5 d-flex flex-column justify-content-center align-items-end">
                        <div className={`mb-2 ${styles.button}`} style={{backgroundColor: "#81AFD9"}}>{t("purpose-culture-title")}</div>
                        <Text color="white" size="17px" align="end">{t("purpose-culture-text")}</Text>
                        <SvgLoader svgProps={{path: "images/icons/line-3.svg", width: "100%", className: "position-absolute", style: {top: "53px", left: "-210px"}}} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="position-relative m-auto mt-3 d-flex flex-column align-items-lg-center" lg={3}>
                    <div className={`mb-2 ${styles.change_btn}`}>{t("purpose-change-title")}</div>
                    <Text color="white" size="17px" align="center">{t("purpose-change-text")}</Text>
                    <SvgLoader svgProps={{path: "images/icons/line-4.svg", width: "100%", className: "position-absolute", style: {top: "-265px"}}} />
                </Col>
            </Row>
        </>
    );
}

export default PruposeDesktop;