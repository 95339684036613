import { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./purpose.module.css";
import { useTranslation } from "react-i18next";
import PruposeDesktop from "../../organisms/prupose-dektop/prupose-desktop";
import PurposeMobile from "../../organisms/purpose-mobile/prupose-mobile";
import Text from "../../atoms/text/text";
import { GlobalContext } from "../../../context/global-context-provider";

const Purpose = (props) => {

    const { windowWidth } = useContext(GlobalContext);
    const {t} = useTranslation();

    return(
        <section id="purpose" className={styles.purpose}>
            <Container className="py-5 position-relative">
                <Row>
                    <Col className={`d-flex flex-lg-column justify-content-center ${windowWidth > 900 ? "position-absolute" : null}`} lg={3}>
                        <Text color="white" size={windowWidth > 900 ? "54px" : "26px"}>{t("purpose-title-1")} <b>{t("purpose-title-2")}</b></Text>
                    </Col>
                </Row>
                {
                (windowWidth >= 1200)
                ?<PruposeDesktop />
                :<PurposeMobile />
                }
            </Container>
        </section>
    );
}

export default Purpose;