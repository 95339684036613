import { useContext, useEffect, useState } from "react";
import styles from "./customers.module.css";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/global-context-provider";

const Customers = () => {

    const { t } = useTranslation();
    const { windowWidth } = useContext(GlobalContext);

    const [array, setArray] = useState([]);
    const [isMobile, setIsMobile] = useState(windowWidth <= 900 ? true : false);

    useEffect(()=>{
        const array = importAll(require.context('./images/', true, /\.(png|jpe?g|svg)$/));
        const rows = array.reduce(function (rows, key, index) { 
            return (index % 6 == 0 ? rows.push([key]) : rows[rows.length-1].push(key)) && rows;
        }, []);
        setArray(rows);
    },[]);

    useEffect(()=>{
        const currentWidth = window.innerWidth;
        let i = 6;

        if (windowWidth <= 900){
            if (isMobile) { return; }
            setIsMobile(true);
            i = 4;
        }else{
            if (!isMobile) { return; }
            setIsMobile(false);
        }

        const array = importAll(require.context('./images/', true, /\.(png|jpe?g|svg)$/));
        const rows = array.reduce(function (rows, key, index) { 
            return (index % i == 0 ? rows.push([key]) : rows[rows.length-1].push(key)) && rows;
        }, []);
        setArray(rows);
    }, [windowWidth])

    const importAll = (r) => {
        return r.keys().map(r);
    }

    return(
        <section id="customers" className={`py-5 ${styles.customers}`}>
            <Container className="my-3">
                <div className={`mx-auto mb-4 mb-lg-5 ${styles.titleContainer}`}>
                    <h2 className={`text-center ${styles.title}`}>
                        <b>
                            <span style={{color: "#419678"}}>{t("customers-title-1")}</span> {t("customers-title-2")} <span style={{color: "#8CB9DF"}}>{t("customers-title-3")}</span> {t("customers-title-4")}
                        </b>
                    </h2>
                </div>
                <div className="my-3">
                    <img className={styles.LogoMap} src="images/icons/mapa.png" alt="mapa" />
                </div>
                {
                    array.map((row,index)=>(
                        <Row key={index} className="my-2 justify-content-center align-items-center">
                            {
                                row.map((src,index)=>(
                                    <Col className={`my-2 d-flex justify-content-center ${styles.logoContainer}`} xs={3} lg={2} key={index}>
                                        <img src={src} alt="logo" />
                                    </Col>
                                ))
                            }
                        </Row>
                    ))
                }
            </Container>
        </section>
    )
}

export default Customers;