import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./components/views/Home";
import Aos from 'aos';
import GlobalContextProvider from "./context/global-context-provider";
import FormValidation from "./form-validation";

const ReactRoutes = (props) => {

    Aos.init({
        duration: 500,
        easing: "ease-in-out",
      });
      
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;

    useEffect(()=>{
        FormValidation();

        if (pathname !== '/')
            navigate('/');
    },[pathname, navigate]);

    return(
        <GlobalContextProvider>
            <Routes>
                <Route path="/" Component={Home} />
            </Routes>
        </GlobalContextProvider>
    )
}

export default ReactRoutes;