import { Nav } from "reactstrap";
import Link from "../../atoms/nav-link/nav-link";
import LanguageSwitch from "../language-switch/language-switch";
import { useTranslation } from "react-i18next";

const DesktopNavbar = (props) => {

    const { t } = useTranslation();

    return(
        <div>
            <Nav className="me-auto">
                <Link linkProps={{href: '#specialties'}}> {t("header-programs")}</Link>
                <Link linkProps={{href: '#services'}}> {t("header-services")}</Link>
                <Link linkProps={{href: '#customers'}}> {t("header-clients")}</Link>
                <Link 
                    itemProps={{style: {marginTop: '5px'}}}
                    linkProps={{href: '#contact-us', style: { backgroundColor: '#719ED1', borderRadius: '20px', padding: '3px 10px', color: 'white' }}}>
                    {t("header-contact")}
                </Link>
                <LanguageSwitch />
            </Nav>
        </div>
    );
}

export default DesktopNavbar;