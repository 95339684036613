import { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./experience.module.css";
import { GlobalContext } from "../../../context/global-context-provider";
import SvgLoader from "../../atoms/svg-loader/svg-loader";
import { useTranslation } from "react-i18next";

const Experience = () => {

    const { windowWidth } = useContext(GlobalContext);
    const { t, i18n } = useTranslation();

    return(
        <section id="experience" className={styles.experience}>
            <Container>
                <Row className="position-relative d-flex justify-content-around">
                    <Col className={`${styles.absolute} ${styles.bottom} ${styles.left}`} lg={3} xs={4}>
                        <h2><b>{t("experience-title-1")}</b></h2>
                        <p className="d-none d-lg-inline-block">{t("experience-text-1")}</p>
                    </Col>
                    <Col lg={3} xs={4}>
                        <h2 className="text-center"><b>{t("experience-title-2")}</b></h2>
                        <p className="d-none d-lg-inline-block">{t("experience-text-2")}</p>
                    </Col>
                    <Col className={`${styles.absolute} ${styles.bottom} ${styles.right}`} lg={3} xs={4}>
                        <h2><b>{t("experience-title-3")}</b></h2>
                        <p className="d-none d-lg-inline-block">{t("experience-text-3")}</p>
                    </Col>
                </Row>
                <SvgLoader svgProps={{path: i18n.language === "es-MX" ? "images/icons/adn-es.svg" : "images/icons/adn-en.svg", width: "100%", height: "100%", className: "d-block m-auto"}} />
                <Row className="position-relative d-flex justify-content-around">
                    <Col className={`${styles.absolute} ${styles.top} ${styles.left}`} lg={3} xs={4}>
                        <h2><b>{t("experience-title-4")}</b></h2>
                        <p className="d-none d-lg-inline-block">{t("experience-text-4")}</p>
                    </Col>
                    <Col lg={3} xs={4}>
                        <h2 className="text-center"><b>{t("experience-title-5")}</b></h2>
                        <p className="d-none d-lg-inline-block">{t("experience-text-5")}</p>
                    </Col>
                    <Col className={`${styles.absolute} ${styles.top} ${styles.right}`} lg={3} xs={4}>
                        <h2><b>{t("experience-title-6")}</b></h2>
                        <p className="d-none d-lg-inline-block">{t("experience-text-6")}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Experience;