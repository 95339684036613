import { useContext } from "react";
import Text from "../../atoms/text/text";
import styles from "./card.module.css";
import { GlobalContext } from "../../../context/global-context-provider";

const Card = (props) => {

    const { windowWidth } = useContext(GlobalContext);

    const {
        image,
        title,
        text,
        bgPosition
    } = props;

    return(
        <div className={styles.flip_card}>
            <div className={styles.flip_card_inner} style={{backgroundImage: `url("${image}")`, backgroundPositionY: windowWidth<900?bgPosition:"0"}}>
                <div className={styles.flip_card_front}>
                    <div className={styles.textContainer}>
                        <Text color="white" size={windowWidth>900?"26px":"15.59px"} align={windowWidth>900?"center":"start"}><b>{title}</b></Text>
                    </div>
                    <div className={styles.overlay1}></div>
                </div>
                <div className={styles.flip_card_back}>
                    <div className={styles.textContainer}>
                        <Text color="white" size={windowWidth>900?"26px":"15.59px"} align={windowWidth>900?"center":"start"}>{text}</Text>
                    </div>
                    <div className={styles.overlay2}></div>
                </div>
            </div>
        </div>
    );
}

export default Card;