import styles from "./hero.module.css";
import { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import SvgLoader from "../../atoms/svg-loader/svg-loader";
import Title from "../../atoms/title/title";
import Text from "../../atoms/text/text";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/global-context-provider";

const Hero = (props) => {

    const { windowWidth } = useContext(GlobalContext);
    const {t} = useTranslation();

    return(
        <section id="hero" className={styles.hero} {...props}>
            <div className={styles.container}></div>
            <Container style={{paddingBottom: "250px", position: "relative", zIndex: 3}}>
                <Row>
                    <Col xxl={8}>
                        <Title>{t("hero-head-1")} <span className="text-primary">{t("hero-head-2")}</span></Title>
                        <Text size="1.3rem">{t("hero-text-1")}</Text>
                        <a href="#contact-us" className={`mt-2 ${styles.button}`}>{t("hero-btn")}</a>
                    </Col>
                </Row>
            </Container>
            <div style={{position: "absolute", bottom: "0", right: "0", zIndex: 1}}>
                {
                (windowWidth >= 900)
                ?<SvgLoader
                    svgProps={{path: "images/icons/mountains.svg", width: "700px"}}
                    />
                :<SvgLoader
                    svgProps={{path: "images/icons/mountains.svg", width: "300px", height: "300px"}}
                    />
                }
            </div>
            <img className={styles.image} src="images/mountains.png" alt="Montañas" />
        </section>
    );
}

export default Hero;