import { Col, Container, Row } from "reactstrap";
import styles from "./phases.module.css";
import SvgLoader from "../../atoms/svg-loader/svg-loader";
import { useTranslation } from "react-i18next";
import Text from "../../atoms/text/text";

const Phases = () => {

    const {t} = useTranslation();

    return(
        <section id="phases" className={styles.phases}>
            <Container className="py-5">
                <Row>
                    <Col className="position-relative" lg={6} xs={4}>
                        <div className={styles.svgContainer}>
                            <SvgLoader svgProps={{path: "images/icons/spiral.svg", width: "100%", height: "100%"}} />
                        </div>
                    </Col>
                    <Col className="d-none d-lg-block px-5 mt-5 pe-0" lg={6}>
                        <div className={`position-relative ${styles.cp1}`} data-aos="fade-up" data-aos-delay="3000">
                            <p className={`${styles.title} ${styles.t1}`}><b>{t("phases-performance-title")}</b></p>
                            <p className={styles.text}>{t("phases-performance-text-1")} <b>{t("phases-performance-text-2")}</b></p>
                            <div className={styles.lineContainer} style={{left: "-82px", top: "40px"}}><SvgLoader svgProps={{path: "images/icons/line-32.svg"}} /></div>
                        </div>
                        <div className={`position-relative ${styles.cp2}`} data-aos="fade-up" data-aos-delay="2000">
                            <p className={`${styles.title} ${styles.t2}`}><b>{t("phases-behavior-title")}</b></p>
                            <p className={styles.text}>{t("phases-behavior-text")}</p>
                            <div className={styles.lineContainer} style={{left: "-108px", top: "20px"}}><SvgLoader svgProps={{path: "images/icons/line-31.svg"}} /></div>
                        </div>
                        <div className={`position-relative d-flex flex-column ${styles.cp3}`} data-aos="fade-up" data-aos-delay="1000">
                            <p className={`${styles.title} ${styles.t3}`}><b>{t("phases-learn-title")}</b></p>
                            <p className={styles.text}>{t("phases-learn-text")}</p>
                            <div className={styles.lineContainer} style={{left: "-135px", top: "20px"}}><SvgLoader svgProps={{path: "images/icons/line-30.svg"}} /></div>
                        </div>
                        <div className={`position-relative w-75 ${styles.cp4}`} data-aos="fade-up">
                            <p className={`${styles.title} ${styles.t4}`}><b>{t("phases-training-title")}</b></p>
                            <p className={styles.text}>{t("phases-training-text")}</p>
                            <div className={styles.lineContainer} style={{left: "-170px", top: "30px"}}><SvgLoader svgProps={{path: "images/icons/line-29.svg"}} /></div>
                        </div>
                    </Col>
                    <Col className="d-block d-lg-none pb-5" xs={8}>
                        <div className="position-relative" style={{marginTop: "2.5rem"}} data-aos="fade-up" data-aos-delay="3000">
                            <Text color="white" size="25.51px" lineHeight="0"><b>{t("phases-performance-title")}</b></Text>
                            <Text color="white" size="8px" lineHeight="10px">{t("phases-performance-text-1")} <b>{t("phases-performance-text-2")}</b></Text>
                        </div>
                        <div className="position-relative d-flex flex-column" style={{marginTop: "2.5rem"}} data-aos="fade-up" data-aos-delay="2000">
                            <Text color="white" size="21.86px" lineHeight="0" bottom=".6rem"><b>{t("phases-behavior-title")}</b></Text>
                            <Text color="white" size="8px" lineHeight="10px">{t("phases-behavior-text")}</Text>
                        </div>
                        <div className="position-relative d-flex flex-column" style={{marginTop: "1.4rem"}} data-aos="fade-up" data-aos-delay="1000">
                            <Text color="white" size="18.22px" lineHeight="0"><b>{t("phases-learn-title")}</b></Text>
                            <Text color="white" size="8px" lineHeight="0">{t("phases-learn-text")}</Text>
                        </div>
                        <div className="position-relative d-flex flex-column" style={{marginTop: "1.5rem"}} data-aos="fade-up">
                            <Text color="white" size="14.58px" lineHeight="0" bottom=".6rem"><b>{t("phases-training-title")}</b></Text>
                            <Text color="white" size="8px" lineHeight="10px"><span className="d-inline-block w-75">{t("phases-training-text")}</span></Text>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Phases;