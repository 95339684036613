import { Col, Container, Row } from "reactstrap";
import styles from "./services-card.module.css";
import { useContext } from "react";
import { GlobalContext } from "../../../context/global-context-provider";

const ServicesCard = (props) => {

    const { windowWidth } = useContext(GlobalContext);

    const {
        bgColor,
        type,
        title,
        text,
        number
    } = props;
    
    return(
        <Container fluid="true" className="pt-3 pt-lg-5" style={{backgroundColor: bgColor}}>
            <Container className={styles.container}>
                <Row>
                    <Col className={`ms-auto m-lg-auto mx-lg-0 ${styles.titleContainer}`} xs={9} lg={6}>
                        {
                            (type === 1 || windowWidth <= 900)
                            ?<p className={styles.title}><b>{title}</b></p>
                            :<p className={styles.text} style={{maxWidth: "85%"}}>{text}</p>
                        }
                    </Col>
                    <Col className="ms-auto m-lg-auto" xs={9} lg={6}>
                        <div className={styles.textContainer}>
                            {
                                (type === 1 || windowWidth <= 900)
                                    ? <p className={styles.text}>{text}</p>
                                    : <p className={styles.title}><b>{title}</b></p>
                            }
                        </div>
                    </Col>
                </Row>
                <div className={styles.number}>{number}</div>
            </Container>
        </Container>
    );
}

export default ServicesCard;