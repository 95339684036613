import { useContext } from "react";
import NavLogo from "../atoms/nav-logo/nav-logo";
import { Container, Navbar } from "reactstrap";
import DesktopNavbar from "../molecules/desktop-navbar/desktop-navbar";
import MobileNavbar from "../molecules/mobile-navbar/mobile-navbar";
import { GlobalContext } from "../../context/global-context-provider";

const Header = (props) => {
    
    const { windowWidth } = useContext(GlobalContext);

    return (
        <Container style={{position: "relative"}}>
            <Navbar {...props} fixed="top" style={{position: "absolute", marginTop: 20}}>
                <NavLogo />
                {
                (windowWidth >= 900)
                ?<DesktopNavbar />
                :<MobileNavbar />
                }
            </Navbar>
        </Container>
    );
}

export default Header;