
const Text = (props) => {

    return(
        <p style={{
            color: props.color ?? "var(--bs-body-color)",
            backgroundColor: props.bgColor ?? "transparent",
            fontSize: props.size ?? "1rem",
            textAlign: props.align ?? "unset",
            lineHeight: props.lineHeight ?? "unset",
            marginBottom: props.bottom ?? "1rem"
        }}>{props.children}</p>
    );
}

export default Text;