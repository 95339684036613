import { useState } from "react";
import { Collapse, Nav, NavbarToggler } from "reactstrap";
import styles from "./mobile-navbar.module.css";
import Link from "../../atoms/nav-link/nav-link";
import LanguageSwitch from "../language-switch/language-switch";
import { useTranslation } from "react-i18next";

const MobileNavbar = (props) => {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return(
        <div className={styles.container}>
            <NavbarToggler className={`${styles.toggler} ${isOpen?styles.open:styles.close}`} onClick={() => setIsOpen(!isOpen)} />
            <Collapse className={styles.collapse} isOpen={isOpen}>
                <Nav className="me-auto d-flex flex-column align-items-center">
                    <Link linkProps={{href: '#specialties', style: {color: 'white'}}}> {t("header-programs")}</Link>
                    <Link linkProps={{href: '#services', style: {color: 'white'}}}> {t("header-services")}</Link>
                    <Link linkProps={{href: '#customers', style: {color: 'white'}}}> {t("header-clients")}</Link>
                    <Link
                        linkProps={{href: '#contact-us', style: { backgroundColor: '#FFFFFF', borderRadius: '20px', padding: '3px 10px', color: '#719ED1' }}}>
                        {t("header-contact")}
                    </Link>
                    <LanguageSwitch />
                </Nav>
            </Collapse>
        </div>
    );
}

export default MobileNavbar;