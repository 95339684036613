import { SvgLoader as Loader, SvgProxy } from "react-svgmt";

const SvgLoader = (props) => {

    const {svgProps, pathProps} = props;

    return(
        <Loader {...svgProps}>
            <SvgProxy selector={"path"} {...pathProps} />
        </Loader>
    )
}

export default SvgLoader;