import React from 'react';
import Header from '../organisms/header';

//templates
import Hero from '../templates/hero/hero';
import Purpose from '../templates/purpose/purpose';
import Phases from '../templates/phases/phases';
import Specialties from '../templates/specialties/specialties';
import Services from '../templates/services/services';
import Experience from '../templates/experience/experience';
import Customers from '../templates/customers/customers';
import AboutUs from '../templates/about-us/about-us';
import ContactUs from '../templates/contact-us/contact-us';

const Home = (props) => {

    return (
        <>
            <header>
                <Header />
            </header>
            <main>
                <Hero id="hero" style={{
                    backgroundImage: 'url("images/clouds.png")',
                    backgroundPosition: 'bottom',
                    position: "relative",
                    }}
                />
                <Purpose />
                <Phases />
                <Specialties />
                <Services />
                <Experience />
                <Customers />
                <AboutUs />
                <ContactUs />
            </main>
        </>
    );
}

export default Home;