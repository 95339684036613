import { Col, Row } from "reactstrap";
import styles from "./purpose-mobile.module.css";
import Text from "../../atoms/text/text";
import { useTranslation } from "react-i18next";
import SvgLoader from "../../atoms/svg-loader/svg-loader";

const PurposeMobile = () => {

    const { t, i18n } = useTranslation();

    return(
        <>
            <Row>
                <Col className="m-auto" lg={6}>
                    <SvgLoader svgProps={{path: i18n.language === "en-US" ? "images/icons/purpose-mobile-en.svg" : "images/icons/purpose-mobile-es.svg", width: "100%", height: "300px"}} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className={`m-auto`} xs={6}>
                    <div className={styles.change_btn}>{t("purpose-change-title")}</div>
                </Col>
            </Row>
        </>
    );
}

export default PurposeMobile;