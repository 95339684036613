import { NavItem, NavLink } from 'reactstrap';
import styles from './nav-link.module.css';

const Link = (props) => {

    const {itemProps, linkProps} = props;

    return(
        <NavItem {...itemProps}>
            <NavLink className={`my-auto d-block ${styles.link}`} {...linkProps} >{props.children}</NavLink>
        </NavItem>
    );
}

export default Link;