import { useContext, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import styles from "./contact-us.module.css";
import { useTranslation } from "react-i18next";
import SvgLoader from "../../atoms/svg-loader/svg-loader";
import axiosApp from "../../../http";
import { GlobalContext } from "../../../context/global-context-provider";
import SweetAlert2 from 'react-sweetalert2';

const ContactUs = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const { t } = useTranslation();
    const { windowWidth } = useContext(GlobalContext);

    const handleSubmit = e => {
        e.preventDefault();
        setIsLoading(true);
        const invalidDomains = ['gmail.com', 'hotmail.com', 'outlook.es', 'yahoo.com', 'live.com'];

        const formData = new FormData(e.target);
        const domain = formData.get('correo').split('@')[1];

        if(invalidDomains.indexOf(domain) === -1) {
			axiosApp.post("_send.php",{
                data: formData,
            })
            .then(response => {
                console.log(response.data);
                setIsLoading(false);
                setSwalProps({
                    show: true,
                    title: t("mail-success-title"),
                    text: t("mail-success-text"),
                    icon: 'success',
                    confirmButtonText: t("mail-btn")
                });
            })
            .catch(error => {
                setIsLoading(false);
                setSwalProps({
                    show: true,
                    title: t("mail-error-title"),
                    text: t("mail-error-text"),
                    icon: 'error',
                    confirmButtonText: t("mail-btn")
                });
            })
		}
        else{
            setIsLoading(false);
            document.getElementById('email').value = '';
        }
    }

    return(
        <section className={`py-5 position-relative ${styles.contactUs}`}>
            <div className={styles.overlay}></div>
            <Container fluid={true} className={`p-0 ${styles.container}`}>
                <Container className={`border-bottom border-white border-2 ${styles.avatarcontainer}`}>
                    <Row>
                        <Col className="pe-4" xs={6} lg={6}>
                            <img className={styles.avatar} src="images/avatar.png" alt="HUGO LÓPEZ" />
                            <div className={`mt-3 ${styles.avatarText}`}>
                                <div className="mb-3 border-bottom border-dark border-2">
                                    <h2><b>{t("contact-us-avatar-title")}</b></h2>
                                    <p><i>{t("contact-us-avatar-subtitle")}</i></p>
                                </div>
                                <p className={styles.text}>"{t("contact-us-avatar-leyend-1")} <i><b>{t("contact-us-avatar-leyend-2")}</b></i>"</p>
                            </div>
                        </Col>
                        <Col className="ps-4 border-start border-dark border-2" xs={6} lg={6}>
                            <p className={styles.text}>{t("contact-us-avatar-text-1")}</p>
                            <p className={styles.text}>{t("contact-us-avatar-text-2")}</p>
                            <p className={styles.text}>{t("contact-us-avatar-text-3")}</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="mb-0 mb-lg-5">
                    <Row className={styles.titleContainer}>
                        <Col>
                            <h2><b>{t("contact-us-title-1")} <span>{t("contact-us-title-2")}</span> {t("contact-us-title-3")} <span>{t("contact-us-title-4")}</span> <span className={styles.bgColorGreen}>{t("contact-us-title-5")}</span></b></h2>
                        </Col>
                    </Row>
                    <div className={styles.separator}></div>
                    <h2 className={`text-start ${styles.subtitle}`}>{t("contact-us-subtitle")}</h2>
                    <form id="contact-us" className="needs-validation" noValidate={true} action="" method="post" onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={6}>
                                <Row>
                                    <Col lg={12}>
                                        <label className={styles.label}htmlFor="name">{t("contact-us-input-1")}</label>
                                        <input type="text" id="name" name="nombre" className={`form-control ${styles.input}`} required />
                                        <div className="invalid-feedback">{t("contact-us-validation-1")}</div>
                                        <div className="valid-feedback">{t("contact-us-success")}</div>
                                    </Col>
                                    <Col lg={12}>
                                        <label className={styles.label} htmlFor="email">{t("contact-us-input-2")}</label>
                                        <input type="email" id="email" name="correo" className={`form-control ${styles.input}`} required />
                                        <div className="invalid-feedback">{t("contact-us-validation-2")}</div>
                                        <div className="valid-feedback">{t("contact-us-success")}</div>
                                    </Col>
                                    <Col lg={12}>
                                        <label className={styles.label} htmlFor="message">{t("contact-us-input-3")}</label>
                                        <textarea type="text" id="message" name="mensaje" className={`form-control ${styles.input}`} required></textarea>
                                        <div className="invalid-feedback">{t("contact-us-validation-3")}</div>
                                        <div className="valid-feedback">{t("contact-us-success")}</div>
                                    </Col>
                                </Row>
                                <button className={styles.button} type="submit">
                                    {
                                        (isLoading)
                                        ?<Spinner />
                                        :<>{t("contact-us-button")}</>
                                    }
                                </button>
                            </Col>
                            <Col lg={6}>
                                <SvgLoader svgProps={{
                                    path: "images/icons/mountains-2.svg",
                                    width: windowWidth > 900 ? "100%" : "60%",
                                    style: {
                                        display: "block",
                                        margin: "auto",
                                        height: windowWidth > 900 ? "350px" : "250px"
                                        }
                                    }}
                                    />
                            </Col>
                        </Row>
                    </form>
                </Container>

                <SweetAlert2 {...swalProps} didClose={()=>{setSwalProps({})}} />
            </Container>
        </section>
    )
}

export default ContactUs;