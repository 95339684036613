import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./services.module.css";
import Text from "../../atoms/text/text";
import { useTranslation } from "react-i18next";
import ServicesCard from "../../molecules/services-card/services-card";
import { GlobalContext } from "../../../context/global-context-provider";

const Services = () => {

    const { windowWidth } = useContext(GlobalContext);
    const { t } = useTranslation();

    return(
        <section id="services" className={styles.services}>
            <Container fluid="true" className={`pt-4 pt-lg-5 pb-3 ${styles.container}`}>
                <Container className="pb-3">
                    <Row style={{backgroundColor: "#122331"}}>
                        <Col lg={12}>
                            <Text color="white" size={windowWidth>900?"54px":"26px"} align="center">{t("services-title-1")} <b>{t("services-title-2")}</b></Text>
                            <hr className="mt-4 text-white border border-1 border-white opacity-100" />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <ServicesCard bgColor="#122331" type={1} title={t("services-card-title-1")} text={t("services-card-text-1")} number="01" />
            <ServicesCard bgColor="#1F3548" type={1} title={t("services-card-title-2")} text={t("services-card-text-2")} number="02" />
            <ServicesCard bgColor="#2C465E" type={2} title={t("services-card-title-3")} text={t("services-card-text-3")} number="03" />
            <ServicesCard bgColor="#3B5875" type={2} title={t("services-card-title-4")} text={t("services-card-text-4")} number="04" />
            <ServicesCard bgColor="#49698C" type={2} title={t("services-card-title-5")} text={t("services-card-text-5")} number="05" />
            <ServicesCard bgColor="#577BA3" type={1} title={t("services-card-title-6")} text={t("services-card-text-6")} number="06" />
            <ServicesCard bgColor="#638CB9" type={1} title={t("services-card-title-7")} text={t("services-card-text-7")} number="07" />
            <ServicesCard bgColor="#729ED0" type={2} title={t("services-card-title-8")} text={t("services-card-text-8")} number="08" />
        </section>
    );
}

export default Services;