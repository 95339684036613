import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const langCode = window.localStorage.getItem('Language') ?? 'en-US';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: langCode,
    lng: langCode,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      "en-US": {
        translation: {
          "header-programs": "PROGRAMS",
          "header-services": "SERVICES",
          "header-clients": "CLIENTS",
          "header-contact": "CONTACT",
          "hero-head-1": "Your organization's potential",
          "hero-head-2": "resides in its people.",
          "hero-text-1": "We design learning programs for improving your team's performance.",
          "hero-btn": "CONTACT US",
          "purpose-title-1": "OUR",
          "purpose-title-2": "MISSION",
          "purpose-ux-text": "Captivate users with group dynamics, games, images, identity and narratives.",
          "purpose-learn-title": "LEARNING",
          "purpose-learn-text": "Create customized experiences that enchance the user's job performance.",
          "purpose-culture-title": "CULTURE",
          "purpose-culture-text": "Design mechanisms that actively involve leaders and build a healthy organizational culture.",
          "purpose-change-title": "CHANGE",
          "purpose-change-text": "When we build upon these 3 pillars, we can change behavior for the better.",
          "phases-performance-title": "PERFORMANCE",
          "phases-performance-text-1": "The users achieve better results and generate greater value for the business.",
          "phases-performance-text-2": "This is the promise of all our learning experiences.",
          "phases-behavior-title": "BEHAVIOR",
          "phases-behavior-text": "The users begin to apply what they have learned in their work.",
          "phases-learn-title": "LEARNING",
          "phases-learn-text": "The program generates new knowledge.",
          "phases-training-title": "TRAINING",
          "phases-training-text": "We start with an experience that achieves the coverage and objectives of the area.",
          "specialties-title-1-1": "WE CAN DESIGN A PROGRAM ON",
          "specialties-title-1-2": "ANY TOPIC, AND FOR ANY INDUSTRY",
          "specialties-title-2-1": "OUR AREAS OF",
          "specialties-title-2-2": "EXPERTISE",
          "specialties-subtitle-1": "However, over the course of +20 years we have gained a lot of experience in",
          "specialties-subtitle-2": "4 fundamental areas for any company.",
          "specialties-card-title-1": "Leadership Development",
          "specialties-card-text-1": "We enhance team leading effectiveness in mid-level to senior management positions, including C-level executives.",
          "specialties-card-title-2": "Diversity and Inlusion",
          "specialties-card-text-2": "We enable leaders and teams to identify unconsciouss biases so that hey can carry out inclusive practices every day.", //ortographic error
          "specialties-card-title-3": "Safety Culture",
          "specialties-card-text-3": "We empower all business levels to take safety ownership through behavioral changes.",
          "specialties-card-title-4": "Onboarding Programs",
          "specialties-card-text-4": "We foster engagement in new hires and jumpstart their learning curve.",
          "specialties-card-title-5": "Psychological Safety",
          "specialties-card-text-5": "We generate practices that create and sustain a culture in which everyone can contribute without fear.",
          "services-title-1": "SERVICE",
          "services-title-2": "CATALOGUE",
          "services-card-title-1": "FACE-TO-FACE TRAINING",
          "services-card-text-1": "Virtual or in person experiences led by a facilitator.",
          "services-card-title-2": "E-LEARNING",
          "services-card-text-2": "Interactive microlearning modules with state-of-the-art multimedia resources.",
          "services-card-title-3": "ON-THE-JOB EXPERIENCES",
          "services-card-text-3": "Self-directed activites to continue learning while in the workplace.",
          "services-card-title-4": "TRAIN-THE-TRAINERS",
          "services-card-text-4": "Coaching for facilitators so that they can deploy the program succesfully while being themselves.",
          "services-card-title-5": "COMMUNICATION CAMPAIGNS",
          "services-card-text-5": "Multimedia resources to promote the experience: videos, posters, invitations, reminders, etc.",
          "services-card-title-6": "IMPLEMENTATION SUPPORT",
          "services-card-text-6": "Constant support to ensure that the program is effectively executed during its whole duration.",
          "services-card-title-7": "ALIGNMENT WORKSHOP",
          "services-card-text-7": "Renewing the way in which C-level executives and upper management collaborate to achieve their goals.",
          "services-card-title-8": "COACHING",
          "services-card-text-8": "Custom mentoring to bring leaders closer to their personal and professional core.",
          "experience-section-title-1": "The",
          "experience-section-title-2": "Experience",
          "experience-title-1": "Behavioral Changes",
          "experience-text-1": "The objective is to always generate new observable behaviors that contribute to better results.",
          "experience-title-2": "High-Impact Content",
          "experience-text-2": "We integrate cutting-edge publications of experts in the field of work psychology alongside the built-in experience of your business.",
          "experience-title-3": "Learning Modalities",
          "experience-text-3": "We select the best learning styles so the user can learn from different approaches.",
          "experience-title-4": "Leadership Roles",
          "experience-text-4": "We involve leaders into the learning process as mentors or facilitators so that they become role models and foster change.",
          "experience-title-5": "User Experience",
          "experience-text-5": "We implement personalized media content, narratives and branding to captivate audiences.",
          "experience-title-6": "Modular Structure",
          "experience-text-6": "We implement each experience during a specific set period of time to promote lasting development.",
          "customers-title-1": "+60",
          "customers-title-2": "CLIENTS AND FRIENDS IN",
          "customers-title-3": "+20",
          "customers-title-4": "YEARS",
          "about-us-title-1": "IN LESS THAN",
          "about-us-title-2": "2 MINUTES",
          "about-us-title-3": ", WE WILL TELL YOU",
          "about-us-title-4": "A LOT ABOUT US",
          "about-us-button": "CHECK OUT THIS VIDEO!",
          "contact-us-avatar-title": "Hugo López",
          "contact-us-avatar-subtitle": "CEO and Co-founder",
          "contact-us-avatar-leyend-1": "For things to truly change and improve, we must address the uncomfortable issues with",
          "contact-us-avatar-leyend-2": "love and courage.",
          "contact-us-avatar-text-1": "Coach, mediator and driving force. Hugo and Itzel founded forteorigen together, a company that for +20 years has helped national and international organizations by creating experiences that enhance and renew the abilities and skills of their people, all while supporting their personal and cultural transformation.",
          "contact-us-avatar-text-2": "Hugo is a graduate in Economics and has a Master in Social Psychology. He has facilitated workshops in multiple countries spanning 3 continents, such as Switzerland, United Kingdom, Egypt, Brazil, the United States of America, Mexico and many others.",
          "contact-us-avatar-text-3": "He has hosted participants across all levels of business, from Advisory Board members, shareholders and management teams to operators and technicians. He is also a musician, singer-songwriter, painter, nature lover and family man. He considers life a constant spiritual journey.",
          "contact-us-title-1": "YOU CAME TO",
          "contact-us-title-2": "THE RIGHT PLACE",
          "contact-us-title-3": "AT THE",
          "contact-us-title-4": "BEST TIME.",
          "contact-us-title-5": "LET'S BEGIN OUR JOURNEY.",
          "contact-us-subtitle": "Contact us!",
          "contact-us-input-1": "Name:",
          "contact-us-validation-1": "Please enter your name",
          "contact-us-input-2": "Your work email address:",
          "contact-us-validation-2": "Please enter a work email",
          "contact-us-input-3": "Tell us, how can we help you?",
          "contact-us-validation-3": "Please enter a message",
          "contact-us-success": "Looks good!",
          "contact-us-button": "SUBMIT",
          "mail-success-title": "Mail sent",
          "mail-success-text": "The email has been sent successfully.",
          "mail-error-title": "Error sending mail",
          "mail-error-text": "An error occurred while sending the email, please try again.",
          "mail-btn": "OK",
        },
      },
      "es-MX": {
        translation: {
          "header-programs": "PROGRAMAS",
          "header-services": "SERVICIOS",
          "header-clients": "CLIENTES",
          "header-contact": "CONTACTO",
          "hero-head-1": "EL POTENCIAL DE TU ORGANIZACIÓN",
          "hero-head-2": "ESTÁ EN TU GENTE",
          "hero-text-1": "Diseñamos programas de aprendizaje para que tu equipo mejore su desempeño",
          "hero-btn": "CONTÁCTANOS",
          "purpose-title-1": "Nuestro",
          "purpose-title-2": "Propósito",
          "purpose-ux-text": "Cautivar al usuario a través de dinámicas, juegos, imágenes, identidad y narrativas.",
          "purpose-learn-title": "APRENDIZAJE",
          "purpose-learn-text": "Crear experiencias a la medida que potencien el desempeño laboral de los usuarios.",
          "purpose-culture-title": "CULTURA",
          "purpose-culture-text": "Diseñar mecanismos que involucren activamente a los líderes y construyan una cultura organizacional sana.",
          "purpose-change-title": "CAMBIO",
          "purpose-change-text": "Cuando construimos sobre estos 3 pilares, logramos nuestro propósito de que el comportamiento cambie.",
          "phases-performance-title": "DESEMPEÑO",
          "phases-performance-text-1": "El usuario logra mejores resultados y genera mayor valor al negocio.",
          "phases-performance-text-2": "Esta es la promesa de todas nuestras experiencias de aprendizaje.",
          "phases-behavior-title": "COMPORTAMIENTOS",
          "phases-behavior-text": "El usuario empieza a aplicar lo aprendido en su trabajo.",
          "phases-learn-title": "APRENDIZAJE",
          "phases-learn-text": "El programa genera nuevos conocimientos.",
          "phases-training-title": "CAPACITACIÓN",
          "phases-training-text": "Comenzamos con una experiencia que logra la cobertura y los objetivos del área.",
          "specialties-title-1-1": "PODEMOS DISEÑAR UN PROGRAMA SOBRE",
          "specialties-title-1-2": "CUALQUIER TEMA Y PARA CUALQUIER INDUSTRIA",
          "specialties-title-2-1": "Nuestras",
          "specialties-title-2-2": "Especialidades",
          "specialties-subtitle-1": "Sin embargo, a lo largo de +20 años hemos adquirido mucha experiencia en",
          "specialties-subtitle-2": "4 temas fundamentales para cualquier empresa.",
          "specialties-card-title-1": "Desarrollo de liderazgo",
          "specialties-card-text-1": "Desde mandos medios hasta la alta dirección, formamos líderes efectivos y humanos.",
          "specialties-card-title-2": "Diversidad e inclusión",
          "specialties-card-text-2": "Permitimos a líderes y equipos identificar sesgos inconscientes para que puedan llevar a cabo prácticas inclusivas todos los días.",
          "specialties-card-title-3": "Cultura de seguridad",
          "specialties-card-text-3": "Ayudamos a que todos los niveles se apropien de la seguridad a través de comportamientos.",
          "specialties-card-title-4": "Onboarding / Inducción",
          "specialties-card-text-4": "Impactamos a los nuevos ingresos para que se enamoren de tu empresa y arranquen con éxito.",
          "specialties-card-title-5": "Seguridad psicológica",
          "specialties-card-text-5": "Instalamos rutinas culturales que hacen que los colaboradores contribuyan libremente.",
          "services-title-1": "Catálogo de",
          "services-title-2": "Servicios",
          "services-card-title-1": "ENTRENAMIENTO PRESENCIAL",
          "services-card-text-1": "Experiencias virtuales o en persona guiadas por un facilitador.",
          "services-card-title-2": "E-LEARNING",
          "services-card-text-2": "Cápsulas de microlearning interactivas y con recursos multimedia innovadores.",
          "services-card-title-3": "EXPERIENCIAS ON-THE-JOB",
          "services-card-text-3": "Actividades autogestionadas para aprender mientras realizan su trabajo real.",
          "services-card-title-4": "TRAIN-THE-TRAINERS",
          "services-card-text-4": "Preparación para que los facilitadores desplieguen el programa con éxito y siendo ellos mismos.",
          "services-card-title-5": "CAMPAÑAS DE COMUNICACIÓN",
          "services-card-text-5": "Recursos para promover la experiencia: videos, posters, invitaciones, recordatorios, etc.",
          "services-card-title-6": "APOYO EN IMPLEMENTACIÓN",
          "services-card-text-6": "Soporte para asegurar que el programa se ejecute con éxito durante toda su duración.",
          "services-card-title-7": "TALLER DE ALINEACIÓN",
          "services-card-text-7": "Renovación de la manera en la que colabora el equipo directivo para lograr sus objetivos.",
          "services-card-title-8": "COACHING",
          "services-card-text-8": "Acompañamiento personal para acercar al líder a su centro personal y profesional.",
          "experience-section-title-1": "La",
          "experience-section-title-2": "Experiencia",
          "experience-title-1": "Cambio de Comportamiento",
          "experience-text-1": "El objetivo siempre es generar nuevos comportamientos observables que contribuyan a mejores resultados.",
          "experience-title-2": "Contenido de alto impacto",
          "experience-text-2": "Integramos obras de autores de vanguardia en psicología del trabajo, así como la experiencia de tu empresa.",
          "experience-title-3": "Modalidades de aprendizaje",
          "experience-text-3": "Seleccionamos las que mejor sirvan para que el usuario aprenda desde distintos ángulos.",
          "experience-title-4": "Rol de los líderes",
          "experience-text-4": "Los involucramos como facilitadores o mentores para poner el ejemplo y apadrinar el cambio.",
          "experience-title-5": "Experiencia de usuario",
          "experience-text-5": "Empleamos medios, narrativas y branding personalizado para deleitar a la audiencia.",
          "experience-title-6": "Estructura modular",
          "experience-text-6": "Desplegamos a lo largo del tiempo para que el aprendizaje se vaya construyendo.",
          "customers-title-1": "+60",
          "customers-title-2": "CLIENTES Y AMIGOS HECHOS EN",
          "customers-title-3": "+20",
          "customers-title-4": "AÑOS",
          "about-us-title-1": "EN MENOS DE",
          "about-us-title-2": "2 MINUTOS",
          "about-us-title-3": ", TE CONTAMOS",
          "about-us-title-4": "MUCHO SOBRE NOSOTROS",
          "about-us-button": "¡CHECA ESTE VIDEO!",
          "contact-us-avatar-title": "Hugo López",
          "contact-us-avatar-subtitle": "Director General y Co-fundador",
          "contact-us-avatar-leyend-1": "Para que las cosas verdaderamente cambien y mejoren, debemos abordar los temas más difíciles con",
          "contact-us-avatar-leyend-2": "amor y valentía",
          "contact-us-avatar-text-1": "Hugo López es coach, mediador y líder de negocio. Fundó forteorigen junto con su esposa Itzel hace más de 20 años. Durante ese tiempo, la empresa ha ayudado a organizaciones Fortune 500 a fortalecer, desarrollar y renovar las competencias de sus colaboradores para potenciar su desempeño. Al mismo tiempo, Hugo es guía de la transformación cultural de las organizaciones, acompañando a sus equipos directivos en este proceso.",
          "contact-us-avatar-text-2": "Hugo es Licenciado en Economía y tiene una maestría en Psicología Social. Ha facilitado talleres en tres continentes y múltiples países como Suiza, Reino Unido, Egipto, Brasil, Estados Unidos, México y muchos otros.",
          "contact-us-avatar-text-3": "Hugo ha desarrollado y acompañado a gente de todos los niveles del negocio: desde la primera línea, gerencia media, directivos, accionistas y miembros de consejo. Él es músico, cantautor, pintor, amante de la naturaleza y un hombre de familia. Él considera que la vida es un viaje espiritual constante.",
          "contact-us-title-1": "LLEGASTE AL",
          "contact-us-title-2": "LUGAR INDICADO",
          "contact-us-title-3": "EN EL",
          "contact-us-title-4": "MEJOR MOMENTO.",
          "contact-us-title-5": "COMENCEMOS ESTE VIAJE.",
          "contact-us-subtitle": "¡Contáctanos!",
          "contact-us-input-1": "Nombre:",
          "contact-us-validation-1": "Por favor ingresa tu nombre",
          "contact-us-input-2": "Dirección de correo de tu trabajo:",
          "contact-us-validation-2": "Por favor ingresa un correo empresarial",
          "contact-us-input-3": "Cuéntanos, ¿cómo te podemos ayudar?",
          "contact-us-validation-3": "Por favor ingresa un mensaje",
          "contact-us-success": "¡Se ve bien!",
          "contact-us-button": "ENVIAR",
          "mail-success-title": "Correo enviado",
          "mail-success-text": "Se ha enviado el correo correctamente.",
          "mail-error-title": "Error al enviar correo",
          "mail-error-text": "Se ha producido un error al enviar el correo, por favor intente nuevamente.",
          "mail-btn": "Aceptar",
        },
      }
    },
  });

export default i18n;