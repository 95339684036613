import { NavbarBrand } from "reactstrap";

const NavLogo = (props) => {

    return(
        <NavbarBrand href="#">
            <img src="images/forteorigen-logo-white.png" alt="logo forteorigen" width={150} />
        </NavbarBrand>
    );
}

export default NavLogo;