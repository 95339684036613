import styles from "./language-icon.module.css";

const LanguageIcon = (props) => {

    const {src, func} = props;

    return(
        <div onClick={func} className={styles.iconContainer}>
            <img src={src} alt="language icon" className={styles.icon} />
        </div>
    );
}

export default LanguageIcon;