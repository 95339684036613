import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./about-us.module.css";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/global-context-provider";

const AboutUs = () => {

    const { windowWidth } = useContext(GlobalContext);
    const { t, i18n } = useTranslation();

    const [lang, setLang] = useState(i18n.language);

    useEffect(()=>{
        setLang(i18n.language)
    }, [i18n.language]);

    return(
        <section id="about-us" className={`py-5 ${styles.aboutUs}`}>
            <Container>
                <Row>
                    <Col className="m-auto px-2 d-flex flex-column align-items-center" lg={8}>
                        <h2 className={styles.title}><b>{t("about-us-title-1")} <span>{t("about-us-title-2")}</span>{t("about-us-title-3")} <span>{t("about-us-title-4")}</span></b></h2>
                        <span className={`mt-3 ${styles.button}`}>{t("about-us-button")}</span>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        
                        {
                            (lang === 'en-US')
                            ?<video width="100%" src="https://forteorigen.com/videos/Forte%20Video%2020%20a%C3%B1os%20INGL%C3%89S.mp4" type="video/mp4" controls />
                            :<video width="100%" src="https://forteorigen.com/videos/Forte%20Video%2020%20a%C3%B1os%20ESPA%C3%91OL.mp4" type="video/mp4" controls />
                        }
                        {/* <iframe width="100%" height={windowWidth > 900 ? "729px" : "200px"} src={t("about-us-video")} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> */}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AboutUs;