import { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext([]);

const GlobalContextProvider = (props) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', windowResize);

        return (() => {
            window.removeEventListener('resize', windowResize);
        })
    }, [])

    const windowResize = () => {
        const newWidth = window.innerWidth;
        setWindowWidth(newWidth);
    }

    const data = {
        windowWidth: windowWidth
    }

    return(
        <GlobalContext.Provider value={{windowWidth: windowWidth}}>
        {props.children}
        </GlobalContext.Provider>
    );
}

export default GlobalContextProvider;