import { useState } from "react";
import LanguageIcon from "../../atoms/language-icon/language-icon";
import { useTranslation } from "react-i18next";

const LanguageSwitch = (props) => {

    const [language, setLanguage] = useState(window.localStorage.getItem("Language")??"en-US");
    const {i18n} = useTranslation();

    const handleChange = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
        window.localStorage.setItem("Language", lang);
    }

    return(
        <div className="d-inline mx-3" fluid="true">
            {
            (language === "en-US")
            ?<LanguageIcon src="images/icons/language-english-icon.png" func={()=>handleChange("es-MX")} />
            :<LanguageIcon src="images/icons/language-spanish-icon.png" func={()=>handleChange("en-US")} />
            }
        </div>
    );
}

export default LanguageSwitch;