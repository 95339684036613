import { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./specialties.module.css";
import Text from "../../atoms/text/text";
import { useTranslation } from "react-i18next";
import Card from "../../molecules/card/card";
import { GlobalContext } from "../../../context/global-context-provider";

const Specialties = () => {

    const { windowWidth } = useContext(GlobalContext);
    const { t } = useTranslation();

    return(
        <section id="specialties" className={styles.specialties}>
            <Container className="py-5">
                <Row>
                    <Col className="m-auto" lg={10} xs={11}>
                        <Text color="white" size={windowWidth>900?"54px":"26px"} align="center" lineHeight={windowWidth>900?"4rem":"1.6rem"}>{t("specialties-title-1-1")} <b>{t("specialties-title-1-2")}</b></Text>
                        <Text color="white" size={windowWidth>900?"22px":"9px"} align="center" lineHeight={windowWidth>900?"1.8rem":".6rem"}><span className="m-auto" style={{display: "block", width: "79%"}}>{t("specialties-subtitle-1")} <b>{t("specialties-subtitle-2")}</b></span></Text>
                        <hr className="mt-4 text-white border border-1 border-white opacity-100" />
                        <Text color="white" size={windowWidth>900?"54px":"26px"} align="center" lineHeight={windowWidth>900?"4rem":"1.6rem"}>{t("specialties-title-2-1")} <b>{t("specialties-title-2-2")}</b></Text>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="d-flex flex-column flex-lg-row justify-content-lg-center">
                        <Card image="images/card-background-1.png" title={t("specialties-card-title-1")} text={t("specialties-card-text-1")} bgPosition="-8rem" />
                        <Card image="images/card-background-3.png" title={t("specialties-card-title-3")} text={t("specialties-card-text-3")} bgPosition="-8rem" />
                        <Card image="images/card-background-4.png" title={t("specialties-card-title-4")} text={t("specialties-card-text-4")} bgPosition="-12rem" />
                        <Card image="images/card-background-5.png" title={t("specialties-card-title-5")} text={t("specialties-card-text-5")} bgPosition="-11rem" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Specialties;